// import { getSessionToken, clearSession } from './SessionUtils';

export function getAPIHost(): string {
    return "http://slicefinder.danlowrie.com:8000"
    // return "http://ec2-54-202-15-19.us-west-2.compute.amazonaws.com:8000";
    // return "http://localhost:8000";
    // return "https://a1xbua1yph.execute-api.us-east-1.amazonaws.com/dev";
}

export function getAPIBase(): string {
    return getAPIHost();
}

export function getUrlParameter(name : string) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export async function fakeHttpRequest() {
    await new Promise(resolve => setTimeout(resolve, 1000));
}

export const putRequest = (path : string, data : any = {}) => {

    // Stringify JSON data
    if (data && typeof data === 'object') {
        data = JSON.stringify(data);
    }

    const options : RequestInit = {method: 'PUT', body: data};

    return apiRequest(path, options);
}

export const postRequest = (path : string, data : any = {}) => {

    // Stringify JSON data
    if (data && typeof data === 'object') {
        data = JSON.stringify(data);
    }

    const options : RequestInit = {method: 'POST', body: data};

    return apiRequest(path, options);
}

export const getRequest = (path : string) => {

    const options = {method: 'GET'};

    return apiRequest(path, options);
}

export const deleteRequest = (path : string) => {

    const options = {method: 'DELETE'};

    return apiRequest(path, options);
}

// API wrapper function
const apiRequest = (path : string, userOptions : RequestInit = {}) => {
    // Define default options
    const defaultOptions = {};
    
    let token : any = null; //getSessionToken();
    if (token == null) {
        token = ""
    }

    // Define default headers
    const defaultHeaders = {'Content-Type': 'application/json', 'x-copilot-session' : token};
    const options = {
        // Merge options
        ...defaultOptions,
        ...userOptions,
        // Merge headers
        headers: {
        ...defaultHeaders,
        ...userOptions.headers,
        },
    };

    // Build Url
    const url = getAPIBase() + path;

    // Variable which will be used for storing response
    let response : Response | null = null;

    return fetch(url, options).then(responseObject => {
        
        response = responseObject;

        // HTTP unauthorized
        if (response.status === 403) {
            // clearSession();
            window.location.reload();
        }

        // Check for error HTTP error codes
        if (response.status < 200 || response.status >= 300) {
            // Get response as text
            return response.text();
        }

        // Get response as json
        return response.json();

    }).then(parsedResponse => {
    // "parsedResponse" will be either text or javascript object depending if
    // "response.text()" or "response.json()" got called in the upper scope
    // Check for HTTP error codes
       
        if (response!.status < 200 || response!.status >= 300) {
            // Throw error
            throw parsedResponse;
        }

        // Request succeeded
        return parsedResponse;

    }).catch(error => {
    
        // Throw custom API error
        // If response exists it means HTTP error occured
        if (response) {
            throw new ApiError(`Request failed with status ${ response!.status }.`, error, (response! as Response).status + "");
        } else {
            throw new ApiError(error.toString(), null, 'REQUEST_FAILED');
        }

    });
};

class ApiError {
    response : Response | null;
    message : string;
    status : string;
    constructor(message : string, data : any | null, status : string) {

        try {
            this.response = JSON.parse(data);
        } catch (e) {
            this.response = data;
        }

        this.status = status;
        this.message = message;
    }
}