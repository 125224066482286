import React from 'react';

const Header: React.FC<any> = (props) => {

    const handleInputFocus = (e) => {
        e.target.value = "";
    }

    return (
        <div id="header" className="header">
            <img src="newlogo.gif" alt="Slicefinder. Find your slice!" height="60px" width="195px" className="header-logo" />
            <input id="pac-input" type="text" placeholder="Enter a location" className="header-input" onFocus={handleInputFocus} />
        </div>
    )
}
export default Header