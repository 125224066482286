import React, { useEffect, useReducer, useState } from 'react';
import SFMap from './components/SFMap'
import './App.css';
import { getRequest } from './utilities/APIUtilities';
import StarRatings from 'react-star-ratings';
import Header from './components/Header';

function App() {

    const [selectedRestaurant, setSelectedRestaurant] = useState<any>();

    useEffect(() => {

        if (selectedRestaurant !== undefined && selectedRestaurant != null) {
            const myElement = document.getElementById(selectedRestaurant!.unique_name);
            const restaurantsElement = document.getElementById('restaurants');
            if (myElement != null) {
                var topPos = myElement.offsetTop - restaurantsElement!.offsetTop;
                document.getElementById('restaurants')!.scroll({
                    top: topPos,
                    behavior: 'smooth'
                });
            }
        } else {
            document.getElementById('restaurants')!.scroll({
                top: 0
            });
        }

    }, [selectedRestaurant]);

    const replaceRestaurants = (restaurants : any[]) => {
        restaurantsDispatch({ type: 'RESTAURANTS_REPLACE', restaurants : restaurants});
    }

    const addReviewsToRestaurant = (restaurant : any, reviews: any[]) => {
        restaurantsDispatch({ type: 'RESTAURANTS_ADD_REVIEWS', data : {restaurant : restaurant, reviews : reviews}});
    }

    const restaurantsReducer = (currentRestaurants : any[], action : any) => {

        if (action.type === "RESTAURANTS_REPLACE") {

            const newRestaurants = action.restaurants;

            let prunedRestaurants = currentRestaurants.filter((r) => {
                const foundRestuarant = newRestaurants.find((rest) => r.id === rest.id);
                return (foundRestuarant !== undefined);
            })

            newRestaurants.forEach((nr : any) => {
                if (prunedRestaurants.find((cr : any) => cr.id === nr.id) === undefined) {
                    prunedRestaurants.push(nr);
                }
            });
            
            return prunedRestaurants;

        } else if (action.type === "RESTAURANTS_ADD_REVIEWS") {
            
            const updatedRestaurants  = (currentRestaurants as any[]).map((rest) => {

                const reviews = action.data.reviews;
                if (rest.id === action.data.restaurant.id) {
                    rest.reviews = reviews;
                    return rest
                }
                
                return rest;

            });

            return updatedRestaurants;
        }

        return currentRestaurants;
    }

    const [restaurants, restaurantsDispatch] = useReducer(restaurantsReducer, []);

    const handleMarkerClick = (restaurant : any) => {
        setSelectedRestaurant(restaurant);
        console.log(`handleMarkerClick: ${restaurant.name}`);
    }

    const handleMapChanged = (lat: Number, lng: Number, radius: Number) => {

        // if (navigator.geolocation) {
            
        //     navigator.geolocation.getCurrentPosition((position) => {
        //         console.log("**************");
        //     }, () => {
        //         console.log("Unable to retrieve your location");
        //     });
        // } else {
            
        // }
        
        getRequest(`/restaurants?lat=${lat}&lng=${lng}&dist=${radius}`).then((response) => {

            if (selectedRestaurant !== undefined) {
                const findSelectedRestaurant = response.results.find((r) => r.id === selectedRestaurant);

                if (findSelectedRestaurant === undefined) {
                    setSelectedRestaurant(undefined);
                }
            }
            
            replaceRestaurants(response.results);

            response.results.forEach((rest : any) => {

                getRequest(`/restaurants/${rest.id}/reviews`).then((revResponse : any) => {

                    if (revResponse.results.length > 0) {
                        addReviewsToRestaurant(rest, revResponse.results);
                    }

                })
            });
                
        })
    }

    const isSelectedRestaurant = (rest: any) => {

        if (rest !== undefined && selectedRestaurant !== undefined) {
            return (rest.id === selectedRestaurant.id);
        }

        return false;
    }

    return (
        <div className="App">
            <Header />
            <div className="body">
            <div id="restaurants-map-container" className="restaurants-map-container">
                    <SFMap restaurants={restaurants} handleMapChanged={handleMapChanged} handleMarkerClick={handleMarkerClick} />
                </div>
                <div id="restaurants" className="restaurants-list-container">
                    {restaurants !== undefined && restaurants.map((restaurant: any, index : number) => {
                        return (
                            <div id={restaurant.unique_name} className={isSelectedRestaurant(restaurant) ? 'restaurant-card-selected' : 'restaurant-card'} key={restaurant.unique_name}>
                                <div className="restaurant-name">{index + 1}. {restaurant.name}</div>
                                <div className="rating">
                                    <StarRatings
                                        rating={restaurant.rating_average != null ? parseFloat(restaurant.rating_average) : 0}
                                        starRatedColor="#ED0000"
                                        starDimension="19px"
                                        starSpacing="2px"
                                        isSelectable={false}
                                        changeRating={() => {alert('coming soon!')}}
                                        numberOfStars={5}
                                        name='rating'
                                    />
                                </div>
                                <div className="address">{restaurant.street_address}</div>
                                <div className="address">{restaurant.city}, {restaurant.state} {restaurant.postal_code}</div>
                                <div className="address"><a href={`tel:${restaurant.phone_number}`}>{restaurant.phone_number}</a></div>
                                <div className="review-module">
                                    <div className="review">{restaurant.reviews !== undefined && restaurant.reviews[0]?.description}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default App;
