import React, { useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';

const SFMap: React.FC<any> = (props) => {

    let mapRef = useRef(null);
    let mapsRef = useRef(null);

    let markersRef = useRef<any[]>([]);

    useEffect(() => {
        
        if (mapsRef.current != null) {

            let removedMarkers = markersRef.current.filter((m) => {
                const foundRestuarant = props.restaurants.find((rest) => m.restaurantId === rest.id);
                return (foundRestuarant === undefined);
            })

            //remove old markers
            removedMarkers.forEach((marker: any) => {
                marker.setMap(null);
            });

            markersRef.current = markersRef.current.filter( function( m ) {
                return !removedMarkers.includes( m );
            });

            // markersRef.current = [];

            //create new markers
            var index = 0;
            (props.restaurants as any[]).forEach((rest) => {
                index++;

                const foundMarker = (markersRef.current.find((m) => {
                    return (m.restaurantId === rest.id);
                }))
                
                if (foundMarker === undefined) {
                    
                    const marker = new (mapsRef.current! as any).Marker({
                        position: { lat: parseFloat(rest.latitude), lng: parseFloat(rest.longitude) },
                        // label : {
                        //     text : index.toString(),
                        //     color : 'white'
                        // },
                        map: mapRef.current,
                        icon: {
                            url : `markers/${index}.png`
                        },
                    });

                    marker.addListener("click", () => {
                        props.handleMarkerClick(rest);    
                    });

                    marker.restaurantId = rest.id;
                    
                    markersRef.current.push(marker);
                } else {
                    foundMarker.setIcon({url : `markers/${index}.png`});
                }
                
                
            });
            
        } else {
            console.info("waiting for a mapsRef");
        }
        
        
        
    }, [props]);

    const moveMapToPlace = (map: any, place: any) => {
        map.setCenter(place);
        map.setZoom(15);
    }

    const handleApiLoaded = (map : any, maps : any) => {
        mapRef.current = map;
        mapsRef.current = maps;

        const input = document.getElementById("pac-input");

        console.log(map);
        console.log(maps);
        map.setOptions({styles: [
            {
                featureType: "administrative",
                stylers: [
                  { visibility: "off" }
                ]
            },  
            {
                featureType: "poi",
                stylers: [
                  { visibility: "off" }
                ]
              }
          ]});
        
        const autocomplete = new maps.places.Autocomplete(input);
            // Bind the map's bounds (viewport) property to the autocomplete object,
            // so that the autocomplete requests use the current map bounds for the
            // bounds option in the request.
            autocomplete.bindTo("bounds", map);
            // Set the data fields to return when the user selects a place.
            autocomplete.setFields([
                "address_components",
                "geometry",
                "icon",
                "name",
        ]);

        autocomplete.setTypes(["geocode"]);

        autocomplete.setComponentRestrictions({
            country: ["us", "pr", "vi", "gu", "mp"],
          });

        autocomplete.addListener("place_changed", () => {
            // infowindow.close();
            // marker.setVisible(false);
            let place = autocomplete.getPlace();
  
            if (!place.geometry) {

                let service = new maps.places.PlacesService(map);

                const request = {
                    query: (input as HTMLInputElement).value,
                    fields: ["name", "geometry"],
                };

                service.findPlaceFromQuery(request, (results: any, status: any) => {
                    console.log((input as HTMLInputElement).value);
                    if (status === maps.places.PlacesServiceStatus.OK) {
                        place = (results[0].geometry as any).location
                        console.log(place);
                        moveMapToPlace(map, place);
                    } else {
                        alert("We don't recognize the location you entered.")
                    }
                });
                return;
            }
  
            // If the place has a geometry, then present it on a map.
            moveMapToPlace(map, place.geometry.location);

          });

        map.addListener("idle", () => {
            console.log(`${map.center.lat()}, ${map.center.lng()}`);
            let radius = (map.getBounds().getNorthEast().lat() - map.center.lat()) * 69;
            // console.log(map.zoom);
            props.handleMapChanged(map.center.lat(), map.center.lng(), radius);
            
        });
        
        console.log(map);
        console.log(maps);
      };

    return (
        <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyBOkkElruFJm0S3h_nVCmNiZzywMsXvhZo', libraries: 'places' }}
            defaultCenter={{ lat: 37.7890183, lng: -122.3915063 }}
            defaultZoom={14}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
                handleApiLoaded(map, maps)
            }}
        >
        {/* <MyMarkerComponent
            lat={59.955413}
            lng={30.337844}
            text="My Marker"
        /> */}
        </GoogleMapReact>
    )
}
export default SFMap;